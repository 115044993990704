<template>
  <div
    :data-testid="UI_TEST_ENVS.includes(MODIO_ENV) ? testId : ''"
    class="tw-w-full"
    :class="[
      {
        'tw-container-max-width': !isWidget && !fullWidth,
        'tw-relative': !noRelative,
      },
      panelMargin,
    ]"
  >
    <!-- top error messages -->
    <div v-if="errorMessage && errorPos === 'top'" class="tw-flex tw-flex-col">
      <panel-error :message="errorMessage"></panel-error>
    </div>
    <div
      v-if="!isWidget"
      :id="anchorId"
      class="tw-absolute tw--top-20 tw-h-px tw-w-full"
      style="z-index: -1"
    />

    <!-- header title -->
    <header
      v-if="($slots.header && slots['default']) || (!$slots.header && pillText)"
      class="tw-w-full tw-flex tw-items-start tw-justify-between tw-relative"
      :class="{ 'tw-mb-2': !$slots.header }"
    >
      <div class="tw-w-full tw-flex tw-items-center">
        <slot name="header" />
      </div>

      <!-- super admin / back to top pill -->
      <base-panel-pill v-if="pillText" :label="pillText" />
      <base-panel-pill v-else-if="backToTop" @click="scrollToTop()" />
    </header>

    <div
      class="tw-w-full"
      :class="[
        {
          'tw-pt-4': (pillText || backToTop) && noPadding,
          'tw-global--border-radius': !isWidget,
          'tw-relative': !noRelative,
          'tw-h-full': fullHeight,
        },
        noPadding
          ? 'tw-rounded-tr-none'
          : smallPadding
            ? 'tw-px-4'
            : 'tw-base-panel-px',
        panelBorderColor,
        panelBorderWidth,
        panelBgStyles,
      ]"
    >
      <div
        :class="{
          'tw-opacity-50 tw-border-transparent tw-pointer-events-none':
            disabled,
          'tw-h-full tw-min-h-[inherit]': fullHeight,
        }"
      >
        <base-panel-pill
          v-if="pillText && $slots.header && !slots['default']"
          :label="pillText"
          class="tw-absolute tw--top-1.5 sm:tw--top-5 tw--right-1.5 sm:tw-right-2"
          :class="[panelBorderColor, panelBorderWidth]"
        />
        <!-- inner header title -->
        <header
          v-if="$slots.header && !slots['default']"
          :class="[panelHeaderYPadding]"
        >
          <slot name="header" />
        </header>

        <div
          v-if="slots['default'] || $slots.noHtmlContent || $slots.content"
          class="tw-flex tw-flex-col"
          :class="{
            'tw-py-6': !noPadding && !smallPadding,
            'tw-py-4': smallPadding,
            'tw-h-full tw-min-h-[inherit]': fullHeight,
          }"
        >
          <!-- inner header content -->
          <div
            v-if="$slots.noHtmlContent || $slots.content"
            :class="{ 'tw-mb-6': contentMargin }"
            class="tw-content"
          >
            <p v-if="$slots.noHtmlContent">
              <slot name="noHtmlContent" />
            </p>
            <slot v-else name="content" />
          </div>

          <!-- panel content -->
          <slot :watch-slot="watchSlot" />

          <!-- error messages -->
          <div
            v-if="errorMessage.length && errorPos === 'bottom'"
            class="tw-flex tw-flex-col tw-pt-4 first:tw-pt-0"
          >
            <panel-error :message="errorMessage"></panel-error>
          </div>
        </div>
      </div>

      <!-- footer -->
      <footer
        v-if="$slots.footer"
        class="tw-border-theme-1 tw-py-6"
        :class="noPadding ? 'tw-pb-0' : 'tw-border-t'"
      >
        <div class="tw-w-full tw-flex tw-justify-between tw-items-center">
          <slot name="footer" />
        </div>
      </footer>
    </div>
  </div>
</template>

<script>
import { computed, getCurrentInstance, toRefs, onMounted } from 'vue'
import BasePanelPill from '@components/Misc/BasePanelPill.vue'
import PanelError from '@components/Error/PanelError.vue'
import { UI_TEST_ENVS, MODIO_ENV } from '@config'
import { useSlots } from '@composables'
import {
  isWidget as isWidgetRoute,
  scrollToElementOffset,
  apiErrorsToArray,
  scrollToElement,
} from '@helpers/utils.js'

export default {
  components: {
    BasePanelPill,
    PanelError,
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    error: {
      type: [String, Object],
      default: null,
    },
    errorPos: {
      type: String,
      default: 'bottom',
      validator: (val) => ['top', 'bottom'].includes(val),
    },
    primary: {
      type: Boolean,
      default: false,
    },
    success: {
      type: Boolean,
      default: false,
    },
    danger: {
      type: Boolean,
      default: false,
    },
    warning: {
      type: Boolean,
      default: false,
    },
    pillText: {
      type: String,
      default: null,
    },
    contentMargin: {
      type: Boolean,
      default: true,
    },
    info: {
      type: Boolean,
      default: false,
    },
    anchorId: {
      type: String,
      default: '',
    },
    noHeaderPadding: {
      type: Boolean,
      default: false,
    },
    backToTop: {
      type: Boolean,
      default: false,
    },
    noPadding: {
      type: Boolean,
      default: false,
    },
    smallPadding: {
      type: Boolean,
      default: false,
    },
    extraSmallMargin: {
      type: Boolean,
      default: false,
    },
    smallMargin: {
      type: Boolean,
      default: false,
    },
    noMargin: {
      type: Boolean,
      default: false,
    },
    noRelative: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    fullWidth: {
      type: Boolean,
      default: false,
    },
    fullHeight: {
      type: Boolean,
      default: false,
    },
    lightBackground: {
      type: Boolean,
      default: false,
    },
    testId: {
      type: String,
      default: '',
    },
  },
  setup(props, context) {
    const {
      extraSmallMargin,
      noHeaderPadding,
      lightBackground,
      smallMargin,
      noPadding,
      anchorId,
      noMargin,
      pillText,
      primary,
      success,
      warning,
      loading,
      danger,
      error,
      info,
    } = toRefs(props)

    const { slots, watchSlot } = useSlots(getCurrentInstance().slots)

    const isWidget = isWidgetRoute()

    const errorMessage = computed(() => {
      return apiErrorsToArray(error.value)
    })

    const panelBorderColor = computed(() => {
      if (primary.value) {
        return 'tw-border-primary'
      } else if (success.value) {
        return 'tw-border-success'
      } else if (danger.value) {
        return 'tw-border-danger'
      } else if (warning.value) {
        return 'tw-border-warning'
      } else if (info.value || pillText.value) {
        return 'tw-border-info'
      } else {
        return 'tw-border-transparent'
      }
    })

    const requireStyles = computed(
      () =>
        primary.value ||
        success.value ||
        danger.value ||
        warning.value ||
        info.value
    )

    const panelBorderWidth = computed(() =>
      isWidget ? '' : requireStyles.value ? 'tw-border-2' : ''
    )

    const panelBgStyles = computed(() => {
      if (noPadding.value) {
        return ''
      } else if (loading.value) {
        return 'tw-bg-theme-3 tw-opacity-70'
      } else if (requireStyles.value) {
        return 'tw-bg-dark-3'
      } else if (lightBackground.value) {
        return 'tw-bg-theme-1'
      } else {
        return isWidget ? 'tw-bg-dark' : 'tw-bg-theme-3'
      }
    })

    const panelMargin = computed(() => {
      if (isWidget || noMargin.value) {
        return ''
      }
      return smallMargin.value
        ? 'tw-mb-6'
        : extraSmallMargin.value
          ? 'tw-mb-2 xs:tw-mb-4'
          : 'tw-mb-6'
    })

    const panelHeaderYPadding = computed(() => {
      if (noHeaderPadding.value) {
        return ''
      }
      return context.slots.default ? 'tw-pt-6 xs:tw-pt-10' : 'tw-py-6'
    })

    onMounted(() => {
      if (anchorId.value && location.hash.replace('#', '') === anchorId.value) {
        scrollToElementOffset(anchorId.value)
      }
    })

    function scrollToTop() {
      scrollToElement('bodyTop')
    }

    return {
      panelHeaderYPadding,
      panelBorderWidth,
      panelBorderColor,
      panelBgStyles,
      UI_TEST_ENVS,
      errorMessage,
      scrollToTop,
      panelMargin,
      MODIO_ENV,
      watchSlot,
      isWidget,
      slots,
    }
  },
}
</script>
