<template>
  <loading-input
    v-if="inputLoading"
    :label="label"
    :type="type"
    :cols="cols"
    :rows="rows"
  />

  <div v-else class="tw-text-theme" :class="wrapWidth">
    <div v-if="tip || label" class="tw-flex tw-label-gap">
      <base-input-label
        v-if="label"
        :id-for="inputId"
        :required="required"
        :label="label"
        :tip="tip"
        :tip-hover="tipHover"
        :label-between="labelBetween"
      />
    </div>

    <div class="tw-group">
      <!-- textarea -->
      <textarea
        v-if="type === 'textarea'"
        :id="inputId"
        ref="textInputElement"
        :data-testid="UI_TEST_ENVS.includes(MODIO_ENV) ? testId : ''"
        :name="name"
        :value="dataValue"
        :placeholder="placeholder"
        :disabled="disabled || readonly"
        :maxlength="max || undefined"
        :cols="cols"
        :rows="rows"
        :aria-describedby="`${inputId}Help`"
        :tabindex="disabled ? '-1' : '0'"
        class="tw-flex tw-input--p tw-resize-y tw-min-h-18 tw-global--border-radius tw-transition-colors tw-ease-in-out tw-bg-input-group-hover tw-input--text-size"
        :class="[
          inputBaseStyles,
          inputWidth,
          hasErrors
            ? 'tw-border-danger tw-border-2'
            : !disabled
              ? 'tw-input--focus'
              : '',
        ]"
        @keyup="handleKeyUp"
        @input="onInput($event.target.value)"
        @change="$emit('change', dataValue)"
        @focus="setFocus"
        @blur="setBlur"
      />

      <!-- code digit input (for login and MFA) -->
      <div v-else-if="code" class="tw-w-full tw-flex tw-space-x-4 tw-text-h6">
        <input
          v-for="(_num, index) in Array.from(Array(max).keys())"
          :id="!index ? inputId : `${inputId}-${index}`"
          ref="codeInputRef"
          :key="index"
          class="tw-w-1/5 tw-h-16 tw-text-center"
          :disabled="disabled || readonly"
          :class="[
            inputBaseStyles,
            inputWidth,
            inputHasPrepend,
            inputHasPrependText,
            inputHasAppend,
            inputHasAppendText,
            hasErrors
              ? 'tw-border-danger tw-border-2'
              : disabled
                ? 'tw-border-2 tw-border-transparent'
                : warning
                  ? 'tw-input--focus-within-warning'
                  : danger
                    ? 'tw-input--focus-within-danger'
                    : 'tw-input--focus-within',
            altBg ? 'tw-bg-input-alt-hover' : 'tw-bg-input-hover',
            { '!tw-border-primary': dropdownBorder },
          ]"
          :max="null"
          :maxlength="1"
          @keyup="handleKeyUp($event, index)"
          @input="handleCodeInput($event, index)"
          @change="$emit('change', dataValue)"
          @paste="handleCodePaste"
          @focus="setFocus"
          @blur="setBlur"
        />
      </div>

      <!-- input -->
      <div
        v-else
        class="tw-flex tw-overflow-hidden tw-relative tw-global--border-radius tw-button-transition"
        :class="[
          inputWidth,
          hasErrors
            ? 'tw-border-danger tw-border-2'
            : disabled
              ? 'tw-border-2 tw-border-transparent'
              : warning
                ? 'tw-input--focus-within-warning'
                : danger
                  ? 'tw-input--focus-within-danger'
                  : 'tw-input--focus-within',
          altBg ? 'tw-bg-input-alt-hover' : 'tw-bg-input-hover',
          small ? 'tw-input--height-small' : 'tw-input--height-large',
          { '!tw-border-primary': dropdownBorder },
        ]"
      >
        <!-- prepend -->
        <div
          v-if="$slots.prepend || $slots.prependText"
          class="tw-prepend tw-flex tw-h-full tw-global--border-radius-l"
          :class="[inputBaseStyles, prependText]"
        >
          <div
            class="tw-flex tw-items-center tw-relative tw-h-full"
            :class="{ 'tw-top-px': $slots.prependText }"
          >
            <slot v-if="$slots.prepend" name="prepend" />
            <slot v-if="$slots.prependText" name="prependText" />
          </div>
        </div>

        <!-- input -->
        <input
          :id="inputId"
          ref="inputElement"
          :data-testid="UI_TEST_ENVS.includes(MODIO_ENV) ? testId : ''"
          :name="name"
          :value="url ? removeProtocol(dataValue) : dataValue"
          :placeholder="placeholder"
          :disabled="disabled || readonly"
          :min="isNumberInput ? min : null"
          :max="isNumberInput ? max : null"
          :maxlength="max || undefined"
          :type="inputType"
          :aria-describedby="`${inputId}Help`"
          :tabindex="disabled ? '-1' : '0'"
          class="tw-flex tw-h-full tw-bg-transparent tw-placeholder-input"
          :class="[
            inputBaseStyles,
            inputWidth,
            inputHasPrepend,
            inputHasPrependText,
            inputHasAppend,
            inputHasAppendText,
          ]"
          @keyup="handleKeyUp"
          @input="onInput($event.target.value)"
          @change="$emit('change', dataValue)"
          @focus="setFocus"
          @blur="setBlur"
        />

        <!-- append -->
        <div
          v-if="$slots.append || $slots.appendText"
          class="tw-append tw-flex tw-h-full tw-global--border-radius-r"
          :class="[inputBaseStyles, appendText]"
        >
          <div
            class="tw-flex tw-items-center tw-relative tw-h-full"
            :class="{ 'tw-top-px': $slots.appendText }"
          >
            <slot v-if="$slots.append" name="append" />
            <slot v-if="$slots.appendText" name="appendText" />
          </div>
        </div>
      </div>
    </div>

    <div
      v-if="hasCountOrHint || hasErrors"
      class="tw-flex tw-flex-col tw-hint-gap tw-space-y-1"
    >
      <base-input-errors v-if="!hideErrorText" :errors="errors" align="left" />

      <character-count
        v-if="showCount"
        class="tw-button-transition tw-opacity-50"
        :count="dataValue ? dataValue.length : 0"
        :min="min"
        :max="max"
      />

      <base-input-hint v-if="hint" :id="`${inputId}Help`">
        {{ hint }}
      </base-input-hint>
    </div>
  </div>
</template>

<script>
import { watch, computed, onMounted, ref, toRefs, inject } from 'vue'
import { genHtmlId, removeProtocol, isObj } from '@helpers/utils.js'
import CharacterCount from '@components/Input/CharacterCount.vue'
import LoadingInput from '@components/Loading/LoadingInput.vue'
import { MODIO_ENV, UI_TEST_ENVS } from '@config'

export default {
  components: {
    CharacterCount,
    LoadingInput,
  },
  props: {
    id: {
      type: [String, Number],
      default: null,
    },
    name: {
      type: String,
      default: null,
    },
    modelValue: {
      type: null,
      default: null,
    },
    placeholder: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'text',
    },
    cols: {
      type: String,
      default: '30',
    },
    rows: {
      type: String,
      default: '10',
    },
    required: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: '',
    },
    tip: {
      type: String,
      default: '',
    },
    tipHover: {
      type: Boolean,
      default: false,
    },
    labelBetween: {
      type: Boolean,
      default: true,
    },
    hint: {
      type: String,
      default: '',
    },
    focus: {
      type: Boolean,
      default: false,
    },
    url: {
      type: Boolean,
      default: false,
    },
    errors: {
      type: Array,
      default: () => [],
    },
    warning: {
      type: Boolean,
      default: false,
    },
    danger: {
      type: Boolean,
      default: false,
    },
    min: {
      type: Number,
      default: 0,
    },
    max: {
      type: Number,
      required: true,
    },
    clamp: {
      type: Boolean,
      default: true,
    },
    showCount: {
      type: Boolean,
      default: false,
    },
    fullWidth: {
      type: Boolean,
      default: true,
    },
    inputLoading: {
      type: Boolean,
      default: false,
    },
    altBg: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
    hideErrorText: {
      type: Boolean,
      default: false,
    },
    noDecimal: {
      type: Boolean,
      default: false,
    },
    testId: {
      type: String,
      default: '',
    },
    dropdownBorder: {
      type: Boolean,
      default: false,
    },
    code: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['input', 'change', 'focus', 'blur', 'enter', 'validate'],
  setup(props, { emit, slots }) {
    const {
      modelValue,
      fullWidth,
      noDecimal,
      showCount,
      disabled,
      errors,
      focus,
      clamp,
      code,
      hint,
      type,
      min,
      max,
      url,
      id,
    } = toRefs(props)

    const inputId = id.value ? id.value : genHtmlId()

    const inputElement = ref(null)
    const textInputElement = ref(null)
    const codeInputRef = ref(null)
    const isFocused = ref(false)
    const pastedCode = ref(false)
    const hasErrors = computed(() => !!errors.value?.length)
    const dataValueState = ref(modelValue.value)
    const dataValue = computed(() => dataValueState.value)
    const readonly = inject('saving', false)

    watch(modelValue, (value) => {
      if (isObj(value)) {
        if (value.start === null && value.end === null) {
          dataValueState.value = ''
        } else {
          dataValueState.value = `${value.start} - ${value.end}`
        }
      } else {
        dataValueState.value = value
      }
    })

    onMounted(() => {
      if (focus.value) {
        if (textInputElement.value) {
          textInputElement.value.focus()
        }

        if (inputElement.value) {
          inputElement.value.focus()
        }

        if (code.value && codeInputRef.value) {
          codeInputRef.value[0]?.focus()
        }
      }
    })

    const isNumberInput = computed(() => type.value === 'number')

    const hasCountOrHint = computed(
      () =>
        (showCount.value && (min.value !== 0 || max.value !== 0)) ||
        hint.value !== ''
    )

    const inputBaseStyles = computed(() => {
      let classList = 'tw-leading-normal tw-outline-none tw-appearance-none'
      if (disabled.value) {
        return `${classList} tw-cursor-default`
      }
      return classList
    })

    const inputWidth = computed(() => {
      if (fullWidth.value) {
        return 'tw-w-full'
      }
      return ''
    })

    const inputHasPrepend = computed(() => {
      if (slots.prepend || slots.prependText) {
        return ''
      }
      return 'tw-global--border-radius-l'
    })

    const inputHasAppend = computed(() => {
      if (slots.append || slots.appendText) {
        return ''
      }
      return 'tw-global--border-radius-r'
    })

    const inputHasPrependText = computed(() => {
      if (slots.prependText) {
        return 'tw-pl-1'
      } else if (slots.prepend) {
        return 'tw-pl-2'
      }
      return 'tw-input--pl'
    })

    const inputHasAppendText = computed(() => {
      if (slots.appendText) {
        return 'tw-pr-1'
      } else if (slots.append) {
        return 'tw-pr-2'
      }
      return 'tw-input--pr'
    })

    const prependText = computed(() => {
      if (slots.prependText) {
        return 'tw-input--pl tw-pr-px'
      }
      return 'tw-input--pl tw-pr-1'
    })

    const appendText = computed(() => {
      if (slots.appendText) {
        return 'tw-pl-px tw-pr-2'
      }
      return 'tw-pl-1 tw-input--pr'
    })

    const wrapWidth = computed(() => {
      if (fullWidth.value) {
        return 'tw-block tw-w-full'
      }
      return 'tw-inline-block'
    })

    const inputType = computed(() => {
      return url.value ? 'url' : type.value
    })

    function setFocus() {
      isFocused.value = true
      emit('focus')
    }

    function setBlur(event) {
      isFocused.value = false
      emit('blur', event.target.value)
      if (isNumberInput.value && clamp.value) {
        if (event.target.value < min.value) {
          onInput(min.value)
        } else if (event.target.value > max.value) {
          onInput(max.value)
        }
      }
    }

    function onInput(input, index) {
      let result = input
      if (isNumberInput.value) {
        if (noDecimal.value && input) {
          result = Math.floor(input)
        }
        if (max.value) {
          const maxLen = max.value.toString().length + (noDecimal.value ? 0 : 3)
          if (result.toString().length > maxLen) {
            result = result.toString().substring(0, maxLen)
          }
        }
      } else if (max.value && input.length > max.value) {
        result = input.substring(0, max.value)
      }

      if (code.value) {
        result = modelValue.value
        if (input.length > 1) {
          emit('input', input)
          return
        }

        if (input) {
          if (index === result.length) {
            result = `${result}${input}`
          } else {
            result = result
              .split('')
              .toSpliced(index, result.length === max.value ? 1 : 0, input)
              .join('')
          }
        } else {
          result =
            result.substring(0, index) +
            result.substring(index + 1, result.length)
        }
      }

      emit('input', result)
    }

    function handleKeyUp(event, index) {
      if (code.value && event.key === 'Backspace' && index > 0) {
        codeInputRef.value[index - 1]?.focus()
      }

      dataValueState.value = event.target.value

      if (
        (event.code === 'Enter' || event.code === 'NumpadEnter') &&
        (type.value !== 'textarea' || event.ctrlKey)
      ) {
        emit('enter')
      } else {
        emit('validate', event.target.value)
      }
    }

    function handleCodeInput(event, index) {
      if (pastedCode.value) {
        pastedCode.value = false
        return
      }

      onInput(event.target.value, index)
      if (index < max.value - 1 && event.target.value) {
        codeInputRef.value[index + 1]?.focus()
      }
    }

    function handleCodePaste(event) {
      pastedCode.value = true
      let clipboardCode = event.clipboardData
        .getData('text')
        .substring(0, max.value)

      onInput(clipboardCode)

      for (let i = 0; i < codeInputRef.value.length; i++) {
        codeInputRef.value[i].value = clipboardCode.split('')[i] || ''
      }
    }

    return {
      inputHasPrependText,
      inputHasAppendText,
      textInputElement,
      handleCodeInput,
      inputBaseStyles,
      handleCodePaste,
      inputHasPrepend,
      inputHasAppend,
      removeProtocol,
      hasCountOrHint,
      isNumberInput,
      inputElement,
      UI_TEST_ENVS,
      codeInputRef,
      handleKeyUp,
      prependText,
      inputWidth,
      appendText,
      MODIO_ENV,
      hasErrors,
      wrapWidth,
      dataValue,
      inputType,
      isFocused,
      readonly,
      setFocus,
      onInput,
      setBlur,
      inputId,
    }
  },
}
</script>

<style lang="css">
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type='number'] {
  -moz-appearance: textfield; /* Firefox */
}

.tw-prepend button,
.tw-append button {
  margin-right: -0.5rem;
  margin-left: -0.5rem;
}
</style>
